import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import useTheme from 'hooks/useTheme'
import styles from './FeaturedPressCarousel.module.scss'
import { PRESS_ROUTE } from 'constants/navigation'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { Grid, Paper } from '@ffn/sunbeam'
import { Typography, MediaImageStandard } from 'components/Contentful'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { BLOCKS } from '@contentful/rich-text-types'
import { TitleDivider } from 'components/TitleDivider'
import { Carousel } from 'components/Carousels'

function Card({
  item: {
    fields: { title, subtitle, articleMainPhoto, publishDate, slug, storyType },
  },
  priority,
}) {
  // This is a new implementation from Flat Carousel
  // we are using anything smaller than lg as our mobile for this one
  // for now though, we will make a medium screen resolution later
  // *** UPDATE 7/8/22 ***
  // The lg breakpoint was changed (now 1024px) and xl (1280) is closer to the old lg (1200).
  const smallers = useViewportSmallerThan(BREAKPOINTS.sm)
  const isMedium = useViewportSmallerThan(BREAKPOINTS.md)
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const smallerxl = useViewportSmallerThan(BREAKPOINTS.xl)
  const smallerxxl = useViewportSmallerThan(BREAKPOINTS.xxl)
  const titleLength = !isMobile && smallerxl ? 50 : 70
  const shortTitle = title.length > titleLength ? title.substring(0, titleLength) + '...' : title
  let shortSubTitle = subtitle
  if (subtitle?.content[0]?.content[0]?.value) {
    shortSubTitle.content[0].content[0].value =
      subtitle?.content[0]?.content[0]?.value?.length > 110
        ? subtitle?.content[0]?.content[0]?.value?.substring(0, 110) + '...'
        : subtitle?.content[0]?.content[0]?.value
  }

  const CardContent = (
    <Grid
      container
      direction={{ xs: 'column', lg: 'row' }}
      justifyContent={{ xs: 'flex-start', lg: 'flex-start' }}
      alignItems={{ xs: 'stretch', lg: 'center' }}
      spacing={{ xs: 4 }}
    >
      <Grid container direction="column" item xs={12} lg={7}>
        <MediaImageStandard
          content={articleMainPhoto}
          layout="fill"
          height={smallers ? 150 : isMedium ? 250 : smallerxl ? 290 : 356}
          priority={priority}
          className={styles.image}
        />
      </Grid>
      <Grid container item xs={12} lg={5}>
        <Grid item xs={12}>
          <Typography
            content={storyType}
            className={styles['lead-text']}
            variant="displayS10"
            fontWeight="regular"
          />
        </Grid>
        <Grid item xs={12}>
          <AchieveLink href={`${PRESS_ROUTE}/${slug}`} className={styles['link']}>
            <Typography
              content={shortTitle}
              variant={smallerxxl ? 'displayS20' : 'displayS30'}
              className={styles['heading-text']}
              fontWeight="medium"
            />
          </AchieveLink>
        </Grid>
        {publishDate && (
          <Grid item xs={12}>
            <Typography
              content={new Date(publishDate)
                .toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })
                .toString()}
              fontWeight="regular"
              className={styles['date']}
              variant="bodyS30"
            />
          </Grid>
        )}
        {shortSubTitle && (
          <Grid item xs={12}>
            <Typography
              content={shortSubTitle}
              fontWeight="regular"
              className={styles['supporting-text']}
              variant="bodyS30"
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <AchieveLink href={`${PRESS_ROUTE}/${slug}`} className={styles['link']}>
            <Typography
              content="Read More"
              fontWeight="bold"
              variant={isMobile ? 'displayXS30' : 'displayS11CTA'}
              styleOverride={{
                [BLOCKS.PARAGRAPH]: {
                  marginTop: '1em',
                },
              }}
            />
          </AchieveLink>
        </Grid>
      </Grid>
    </Grid>
  )

  return smallerxl ? (
    <>{CardContent}</>
  ) : (
    <Paper elevation={0} className={styles['paper']}>
      <>{CardContent}</>
    </Paper>
  )
}

export const FeaturedPressCarousel = ({ content }) => {
  const isMobile = useViewportSmallerThan('lg')
  const sectionIdPrefix = `press-carousel-more-resources-carousel`
  const { featuredPressCarouselTitle, featuredArticles } = useMemoizedContentGetter(content, [
    'featuredPressCarouselTitle',
    'featuredArticles',
  ])
  const theme = useTheme()
  return (
    <Section
      backgroundColor={theme?.sb?.colors?.primary?.highlightBlue}
      className={styles['featured-article-carousel']}
    >
      <Section className={styles['section']}>
        <TitleDivider
          className={styles['paper-carousel-title-divider']}
          title={featuredPressCarouselTitle}
          isMobile={isMobile}
          data-testid={`featured-press-carousel-title`}
        />
        <Carousel
          carouselItems={featuredArticles.items}
          itemComponent={Card}
          variant="preview"
          idPrefix={sectionIdPrefix}
        />
      </Section>
    </Section>
  )
}
