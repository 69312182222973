import styles from './ValueProps.module.scss'
import { Grid } from '@ffn/sunbeam'
import Section from 'components/Section'
import { CardValueProp } from 'components/Cards'
import { TitleDivider } from 'components/TitleDivider'
import { BLOCKS } from '@contentful/rich-text-types'
import { LinkButton } from 'components/Contentful'
import { useIsMobile } from 'utils/mui'
import { useMemoizedContentGetter } from 'utils/contentful'
import useTheme from 'hooks/useTheme'

function ValueProps({ content }) {
  const { title, subtitle, grid, theme, linkButton } = useMemoizedContentGetter(content, [
    'title',
    'subtitle',
    'grid',
    'theme',
    'linkButton',
  ])

  const isMobile = useIsMobile()

  const fieldName = content.fields.fieldName

  const achieveTheme = useTheme()
  const backgroundColor = theme?.theme[0]
    ? achieveTheme?.sb?.colors?.primary?.highlightBlue
    : achieveTheme?.sb?.colors?.neutral?.white

  return (
    <Section
      backgroundColor={backgroundColor}
      data-testid="value-prop-section"
      className={styles['section']}
    >
      <Grid data-testid="value-prop-grid-container" className={styles['container-grid']}>
        <div className={styles['grid-wrapper']}>
          <TitleDivider
            data-testid="value-prop-title-divider"
            title={title}
            subtitle={subtitle}
            isMobile={isMobile}
          />
        </div>
        <Grid
          data-testid="value-prop-grid-card-container"
          container
          className={styles['card-grid']}
        >
          {grid?.gridItems.map((item, idx) => (
            <Grid
              data-testid={`value-prop-grid-card-${idx}`}
              className={styles['value-prop-grid-card']}
              item
              xs={12}
              lg={12 / grid.columns}
              key={idx}
            >
              <CardValueProp content={item} isMobile={isMobile} breakline={fieldName} />
            </Grid>
          ))}
        </Grid>
        {linkButton && (
          <div className={styles['button-wrapper']}>
            <LinkButton
              className={styles['button']}
              content={linkButton}
              typographicOptions={{
                variantOverride: { [BLOCKS.PARAGRAPH]: isMobile ? 'displayXS30' : 'displayS10' },
                fontWeight: 'bold',
                'data-testid': 'value-prop-button-text',
                className: styles['typography'],
              }}
              color="primary"
              data-testid="value-prop-button"
              track={{
                nav_link_section: 'Value Props',
                click_type: 'Click Link',
                click_position: '0',
                click_id: 'Get Started',
                form_ss_amount: 1000,
                track_event: 'internal_campaign_click',
              }}
            />
          </div>
        )}
      </Grid>
    </Section>
  )
}

export { ValueProps }
