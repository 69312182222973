import Section from 'components/Section'
import { Typography, MediaImageStandard } from 'components/Contentful'
import { Grid, Paper } from '@ffn/sunbeam'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { BLOCKS } from '@contentful/rich-text-types'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { TitleDivider } from 'components/TitleDivider'
import { Carousel } from 'components/Carousels'
import styles from './PaperCarousel.module.scss'

function Card({
  item: {
    fields: {
      cardImage,
      cardText,
      cardLink: {
        fields: { linkText, linkHref },
      },
    },
  },
  priority,
}) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.xl)
  const [leadText, heading, supportingText] = cardText?.content ?? []
  const CardContent = (
    <Grid
      container
      direction={{ xs: 'column', lg: 'row' }}
      justifyContent={{ xs: 'flex-start', lg: 'flex-start' }}
      alignItems={{ xs: 'stretch', lg: 'center' }}
      spacing={{ xs: 4 }}
    >
      <Grid item xs={12} lg={7}>
        <MediaImageStandard
          content={cardImage}
          layout="fill"
          height={isMobile ? 300 : 400}
          objectPosition="top"
          priority={priority}
          className={styles.image}
        />
      </Grid>
      <Grid container item xs={12} lg={5}>
        <Grid item xs={12}>
          <Typography
            content={leadText}
            className={styles['lead-text']}
            variant={isMobile ? 'displayXS30' : 'displayS10'}
          />
        </Grid>
        <Grid item xs={12}>
          <AchieveLink href={linkHref} className={styles['title-link']}>
            <Typography
              content={heading}
              variant={isMobile ? 'displayS20' : 'displayM20'}
              className={styles['heading-text']}
              fontWeight="medium"
            />
          </AchieveLink>
        </Grid>
        <Grid item xs={12}>
          <Typography
            content={supportingText}
            fontWeight={isMobile ? 'light' : 'regular'}
            className={styles['supporting-text']}
            variant={isMobile ? 'bodyS30' : 'bodyS40'}
          />
        </Grid>
        <Grid item xs={12}>
          <AchieveLink href={linkHref} className={styles['link']}>
            <Typography
              content={linkText}
              fontWeight="bold"
              variant={isMobile ? 'displayXS30' : 'displayS11CTA'}
              styleOverride={{
                [BLOCKS.PARAGRAPH]: {
                  marginTop: '1em',
                },
              }}
            />
          </AchieveLink>
        </Grid>
      </Grid>
    </Grid>
  )

  return isMobile ? (
    <>{CardContent}</>
  ) : (
    <Paper elevation={0} className={styles['paper']}>
      <>{CardContent}</>
    </Paper>
  )
}

function PaperCarousel({ title, carouselItems, idPrefix = 'page' }) {
  // we are using anything smaller than lg as our mobile for this one
  // for now though, we will make a medium screen resolution later
  // *** UPDATE 7/8/22 ***
  // The lg breakpoint was changed (now 1024px) and xl (1280) is closer to the old lg (1200).
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const sectionIdPrefix = `${idPrefix}-more-resources-carousel`

  return (
    <Section className={styles['section']}>
      <TitleDivider
        className={styles['paper-carousel-title-divider']}
        title={title}
        isMobile={isMobile}
        data-testid={`${idPrefix}-title`}
      />
      <Carousel
        carouselItems={carouselItems}
        itemComponent={Card}
        variant="preview"
        idPrefix={sectionIdPrefix}
      />
    </Section>
  )
}

export { PaperCarousel }
