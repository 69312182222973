import styles from './ScrollToNext.module.scss'

const scrollDown = (ref, behavior, scrollDiff) => {
  window.scrollTo({
    top: ref.current.offsetTop + ref.current.clientHeight + scrollDiff,
    behavior: behavior,
  })
}
function ScrollToNext({ currentSection, behavior = 'smooth', scrollDiff = 10, children }) {
  return (
    <a
      className={styles['scroll-to-next-button']}
      onClick={() => scrollDown(currentSection, behavior, scrollDiff)}
    >
      {children}
    </a>
  )
}
export default ScrollToNext
