import styles from 'components/Cards/CardValueProp/CardValueProp.module.scss'
import { Card, CardContent, CardMedia as SunbeamCardMedia, Grid } from '@ffn/sunbeam'
import { MediaImage, Typography } from 'components/Contentful'
import { BLOCKS } from '@contentful/rich-text-types'

function CardValueProp({ content, isMobile, breakline }) {
  const { cardTitleText, cardText, cardImage } = content?.fields || {}
  breakline = ['aboutUsCulture'].includes(breakline)

  return (
    <Card variant="none" data-testid="card-container" className={styles['card']}>
      <CardContent data-testid="card-content" className={styles['card-content']}>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={styles['card-content-grid']}
        >
          {cardImage && (
            <Grid item xs={12} lg={12} className={styles['image-grid']}>
              <SunbeamCardMedia data-testid="sunbeam-card-media">
                <MediaImage
                  data-testid="card-media-image"
                  layout="fixed"
                  width={80}
                  height={80}
                  content={cardImage}
                />
              </SunbeamCardMedia>
            </Grid>
          )}

          {cardTitleText && breakline ? (
            <div className={styles['title-typography-breakline-container']}>
              {cardTitleText.content.map((item, idx) =>
                item?.content[0].value ? (
                  <Grid item xs={12} lg={12} key={idx}>
                    <Typography
                      data-testid="card-title"
                      variantOverride={{
                        [BLOCKS.HEADING_3]: isMobile ? 'displayS20' : 'displayS30',
                      }}
                      fontWeight="medium"
                      className={styles['title-typography-breakline']}
                      content={{ ...cardTitleText, content: [item] }}
                    />
                  </Grid>
                ) : null
              )}
            </div>
          ) : (
            <Grid item xs={12} lg={12}>
              <Typography
                data-testid="card-title"
                variantOverride={{
                  [BLOCKS.HEADING_3]: isMobile ? 'displayS20' : 'displayS30',
                }}
                fontWeight="medium"
                className={styles['title-typography']}
                content={cardTitleText}
              />
            </Grid>
          )}

          {cardText && (
            <Grid item xs={12} lg={12} className={styles['text-grid']}>
              <Typography
                data-testid="card-subtitle"
                className={styles['text-typography']}
                variant={isMobile ? 'bodyS30' : 'bodyS40'}
                component="span"
                content={cardText}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}

export { CardValueProp }
