import { pickAll } from 'ramda'

export const contentMap = (content = [], requestedFields = []) => {
  const fieldMap = content.reduce((accum, { fields }) => {
    const name = fields.fieldName
    if (name) {
      accum[name] = fields
    }
    return accum
  }, {})
  return pickAll(requestedFields, fieldMap)
}
