import {
  Typography as SunbeamTypography,
  Select as SunbeamSelect,
  MenuItem as SunbeamMenuItem,
  InputLabel,
} from '@ffn/sunbeam'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import styles from './MadLib.module.scss'

function MadLibSelect({ options = [], value, onChange, isMobile, dataTestId }) {
  return (
    <>
      <InputLabel htmlFor={dataTestId}></InputLabel>
      <SunbeamSelect
        displayEmpty={true}
        IconComponent={KeyboardArrowDownIcon}
        classes={{
          icon: styles['select-icon'],
          nativeInput: {
            borderBottom: 'none',
          },
        }}
        inputProps={{ id: dataTestId }}
        labelId={dataTestId}
        variant="standard"
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        fullWidth={isMobile}
        data-testid={dataTestId}
      >
        {options.map(({ label, value }, idx) => (
          <SunbeamMenuItem key={label} value={value} autoFocus={idx === 0}>
            <SunbeamTypography
              variant={!isMobile ? 'displayL10' : 'displayS10'}
              fontWeight={!isMobile ? 'regular' : 'bold'}
              color="primary"
              className={styles['select-text']}
            >
              {label}
            </SunbeamTypography>
          </SunbeamMenuItem>
        ))}
      </SunbeamSelect>
    </>
  )
}

export { MadLibSelect }
