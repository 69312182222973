import { Grid } from '@ffn/sunbeam'
import { CardProduct } from 'components/Cards/CardProduct'
import styles from './ThreeProductGrid.module.scss'

function ThreeProductCard({ gridContentArray = [], isMobile = true }) {
  return (
    <>
      {gridContentArray && (
        <Grid
          container
          className={styles['three-product-grid-container']}
          justifyContent={{ xs: 'center' }}
          alignItems={{ xs: 'center' }}
          spacing={isMobile ? '24px' : '32px'}
          rowSpacing={0}
          data-testid="home-product-card-section-card-grid"
        >
          {(gridContentArray.gridItems || []).map((gridContent, index) => {
            return (
              <Grid
                item
                key={`${gridContent?.sys?.id}-${index}`}
                data-testid={`home-product-card-section-card-grid-item-${index}`}
              >
                <CardProduct
                  content={gridContent}
                  isMobile={isMobile}
                  data-testid={`home-product-card-section-card-${index}`}
                />
              </Grid>
            )
          })}
        </Grid>
      )}
    </>
  )
}

export { ThreeProductCard }
