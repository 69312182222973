import { useMemoizedContentGetter } from 'utils/contentful'
import { Typography } from 'components/Contentful/Typography'
import { BLOCKS } from '@contentful/rich-text-types'
import Section from 'components/Section'
import styles from './ProblemStatement.module.scss'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { IconButton } from '@ffn/sunbeam'
import { ChevronDown } from 'react-feather'
import { useRef } from 'react'
import ScrollToNext from 'components/ScrollToNext'
import useTheme from 'hooks/useTheme'

function ProblemStatement({ content }) {
  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const theme = useTheme()
  const currentSection = useRef(null)
  const { title, subtitle } = useMemoizedContentGetter(content, ['title', 'subtitle'])
  return (
    <Section
      className={styles['section']}
      disableGutters
      contain={false}
      backgroundColor={theme?.sb?.colors?.primary?.achieveBlue}
    >
      {title && (
        <Typography
          data-testid="problem-statement-title-text"
          content={title.textContent}
          variantOverride={{ [BLOCKS.HEADING_3]: isMobile ? 'displayM10' : 'displayM20' }}
          fontWeight="bold"
          className={styles['title']}
        />
      )}
      {subtitle && (
        <Typography
          data-testid="problem-statement-subtitle-text"
          content={subtitle.textContent}
          variantOverride={{ [BLOCKS.PARAGRAPH]: isMobile ? 'bodyS40' : 'bodyM10' }}
          fontWeight="regular"
          className={styles['subtitle']}
        />
      )}
      {isMobile && (
        <ScrollToNext currentSection={currentSection}>
          <IconButton size="sm">
            <ChevronDown />
          </IconButton>
        </ScrollToNext>
      )}
    </Section>
  )
}

export { ProblemStatement }
