import { useEffect } from 'react'
import styles from './HeroCarousel.module.scss'
import { Carousel } from 'components/Carousels'
import { MediaImage, Typography, LinkButton } from 'components/Contentful'
import { Box } from '@ffn/sunbeam'
import { BREAKPOINTS } from 'utils/mui'
import useTheme from 'hooks/useTheme'
import useViewPortSize from 'hooks/useViewportSize'
import { trackEvent } from 'utils/analytics'
import { get as _get } from 'lodash'

const FONT_MAP = {
  BUGLINE: {
    [BREAKPOINTS.xs]: { variant: 'displayS10', weight: 'regular' },
    [BREAKPOINTS.sm]: { variant: 'displayS10', weight: 'regular' },
    [BREAKPOINTS.md]: { variant: 'displayS20', weight: 'medium' },
    [BREAKPOINTS.lg]: { variant: 'displayS20', weight: 'medium' },
    [BREAKPOINTS.xl]: { variant: 'displayS20', weight: 'medium' },
    [BREAKPOINTS.xxl]: { variant: 'displayS20', weight: 'medium' },
  },
  TITLE: {
    [BREAKPOINTS.xs]: { variant: 'displayM10', weight: 'bold' },
    [BREAKPOINTS.sm]: { variant: 'displayM10', weight: 'bold' },
    [BREAKPOINTS.md]: { variant: 'displayL10', weight: 'bold' },
    [BREAKPOINTS.lg]: { variant: 'displayL10', weight: 'bold' },
    [BREAKPOINTS.xl]: { variant: 'displayL10', weight: 'bold' },
    [BREAKPOINTS.xxl]: { variant: 'displayL10', weight: 'bold' },
  },
  BUTTON: {
    [BREAKPOINTS.xs]: { variant: 'displayXS30', weight: 'bold' },
    [BREAKPOINTS.sm]: { variant: 'displayXS30', weight: 'bold' },
    [BREAKPOINTS.md]: { variant: 'displayS11CTA', weight: 'bold' },
    [BREAKPOINTS.lg]: { variant: 'displayS11CTA', weight: 'bold' },
    [BREAKPOINTS.xl]: { variant: 'displayS11CTA', weight: 'bold' },
    [BREAKPOINTS.xxl]: { variant: 'displayS11CTA', weight: 'bold' },
  },
}
function Card({
  item: { title, bugline, backgroundMobile, background, alt, buttonCta },
  priority,
  selected,
}) {
  const theme = useTheme()
  const { breakpointLetterSize } = useViewPortSize()
  return (
    <Box
      className={[styles['card-hero'], selected && styles['card-hero-selected']]}
      elevation={selected ? 10 : 1}
    >
      <div className={styles['card-hero-block']}>
        <span className={styles['card-hero-image']}>
          <MediaImage
            content={
              breakpointLetterSize === BREAKPOINTS.xs || breakpointLetterSize === BREAKPOINTS.sm
                ? backgroundMobile
                : background
            }
            alt={alt}
            layout="fill"
            objectFit="cover"
            objectPosition="top"
            width
            height
            priority={priority}
          />
        </span>
        <Box className={styles['card-hero-content']} component="h1">
          <Typography
            content={bugline}
            color={theme?.sb?.colors?.neutral?.white}
            variant={FONT_MAP.BUGLINE[breakpointLetterSize].variant}
            fontWeight={FONT_MAP.BUGLINE[breakpointLetterSize].weight}
            className={styles['card-hero-content-bugline']}
          />
          <Typography
            content={title}
            variant={FONT_MAP.TITLE[breakpointLetterSize].variant}
            fontWeight={FONT_MAP.TITLE[breakpointLetterSize].weight}
            color={theme?.sb?.colors?.neutral?.white}
            className={styles['card-hero-content-info']}
          />
          <LinkButton
            track={{
              nav_link_section: 'Hero Carousel',
              click_type: 'Click Link',
              click_position: '0',
              click_id: 'buttonCta',
              hero_card_text: 'buttonCta',
              form_ss_amount: 1000,
              track_event: 'internal_campaign_click',
            }}
            content={buttonCta}
            typographicOptions={{
              variant: FONT_MAP.BUTTON[breakpointLetterSize].variant,
              fontWeight: FONT_MAP.BUTTON[breakpointLetterSize].weight,
            }}
            size="large"
            classes={{
              root: styles['card-hero-content-link'],
            }}
            onClick={() => {
              trackEvent({
                hero_carousel_section: 'Hero CTA Clicked',
                click_type: 'Button Click',
                click_id: _get(buttonCta, 'linkText.content.0.content.0.value', ''),
                click_url: _get(buttonCta, 'linkHref', ''),
                click_text: _get(buttonCta, 'linkText.content.0.content.0.value', ''),
              })
            }}
          />
        </Box>
      </div>
    </Box>
  )
}

function HeroCarousel({ slides }) {
  useEffect(() => {})

  return (
    <Box className={styles['carousel-container']}>
      <Carousel
        className={styles['hero-carousel-wrapper']}
        carouselItems={slides}
        itemComponent={Card}
        variant="standard"
        idPrefix={`home-hero`}
        withIndicators
        classNameIndicators={styles['hero-carousel-wrapper-indicators']}
        classNameControls={styles['hero-carousel-wrapper-controls']}
        autoPlay
        infinityLoop
      />
    </Box>
  )
}

export { HeroCarousel }
