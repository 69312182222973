import React, { createContext, useRef, useState, useEffect, useContext, useMemo } from 'react'
import { IconButton } from '@ffn/sunbeam'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
import useViewPortSize from 'hooks/useViewportSize'
import useIsSsr from 'hooks/useIsSsr'
import styles from './Carousel.module.scss'
import classNames from 'classnames'
import { iOSSafariMobile } from 'utils/shared'

export const CarouselContext = createContext()

function CarouselControl({
  left,
  right,
  disabled,
  classNameControls,
  centerControlsUltra,
  ...props
}) {
  if (!left && !right) {
    return null
  }
  return (
    <IconButton
      color="secondary"
      className={classNames(
        classNameControls,
        `${styles['carousel-control']} ${
          left
            ? centerControlsUltra
              ? styles['control-left-center']
              : styles['control-left']
            : centerControlsUltra
            ? styles['control-right-center']
            : styles['control-right']
        } ${disabled ? styles['disabled'] : ''}`
      )}
      aria-label={`Display ${left ? 'Previous' : 'Next'} item in carousel`}
      aria-disabled={disabled}
      {...props}
    >
      {left ? <ChevronLeft /> : <ChevronRight />}
    </IconButton>
  )
}

/*

The plan is to setup an observer against the page
and we will detect with the root if it's a viewport height away
and then apply priority true to the media items by passing a prop down to ItemComponent

*/

export function Carousel({
  carouselItems,
  className,
  itemComponent: ItemComponent,
  variant = 'standard',
  idPrefix = 'carousel',
  withIndicators = false,
  classNameIndicators,
  classNameControls,
  interval = 4000,
  autoPlay = false,
  infinityLoop = false,
  centerControlsUltra = false,
}) {
  const isSsr = useIsSsr()
  const carouselContainerRef = useRef(null)
  const itemsRef = useRef([])
  itemsRef.current = []
  let refIds = []
  const [currentItem, setCurrentItem] = useState()
  const [isSafari, setIsSafari] = useState(false)
  const useIntersectionObserverRef = useRef()
  // Use screen height to set intersection observer root margin
  const { screenHeight } = useViewPortSize()
  const inViewport = useIntersectionObserver(useIntersectionObserverRef, screenHeight)
  const observerRef = useRef()

  const [onPause, setOnPause] = useState(false)
  const [onHover, setOnHover] = useState(false)
  const [onView, setOnView] = useState(true)
  const [lastClick, setLastClick] = useState(null)
  const [carouselItemsRender, setCarouselItemsRender] = useState([])

  const itemIDs = useMemo(() => carouselItems.map((item) => item.sys.id), [carouselItems])
  const currentIndex = useMemo(() => itemIDs.indexOf(currentItem), [currentItem, itemIDs])

  useEffect(() => {
    if (!isSsr) {
      // check If current browser is a safari browser
      // Check if we have available the SafariRemoteNotification function on the window.HTMLElement object when use the window['safari'].pushNotification
      setIsSafari(
        /constructor/i.test(window.HTMLElement) ||
          (function (p) {
            return p.toString() === '[object SafariRemoteNotification]'
          })(
            !window['safari'] ||
              (typeof safari !== 'undefined' && window['safari'].pushNotification)
          ) ||
          iOSSafariMobile(navigator.userAgent)
      )
    }
  }, [isSsr])

  useEffect(() => {
    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setCurrentItem(entry.target.id)
        }
      },
      {
        root: carouselContainerRef.current,
        threshold: 0.7,
      }
    )
    itemsRef.current.map((e) => observerRef.current.observe(e))
    return () => {
      if (!(observerRef.current instanceof IntersectionObserver)) return
      carouselItemsRender.forEach((item) => {
        try {
          observerRef.current.unobserve(itemsRef.current[refIds.indexOf(item.sys.id)])
        } catch (e) {
          // prevent errors on hot reload
          // eslint-disable-line no-empty
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carouselItemsRender])

  useEffect(() => {
    if (autoPlay && !onPause && onView && !onHover) {
      const intervaltest = setInterval(() => {
        onClickNext()
      }, interval)
      return () => clearInterval(intervaltest)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPlay, infinityLoop, interval, onPause, currentIndex, onView, onHover, itemIDs, refIds])

  useEffect(() => {
    if (isSsr) {
      // no window to measure during SSR
      return
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSsr])

  const scrollHandler = () => {
    const lineTop = window.pageYOffset
    const borderTop = useIntersectionObserverRef.current.offsetTop
    const borderBotton = borderTop + useIntersectionObserverRef.current.offsetHeight
    setOnView(!(lineTop >= borderBotton))
  }

  useEffect(() => {
    setCarouselItemsRender(carouselItems)
  }, [carouselItems, infinityLoop, currentIndex, isSafari])

  const setItemsRef = (el, id, pos) => {
    refIds[pos] = id
    itemsRef.current[pos] = el
    return itemsRef.current[pos]
  }

  const showItem = (id) => {
    const actualPos = refIds.indexOf(currentItem)
    const newPos = refIds.indexOf(id)
    const widthScroll =
      variant == 'standard'
        ? carouselContainerRef.current.getBoundingClientRect().width
        : screenHeight - 200
    if (actualPos === newPos) return
    const diference = widthScroll * (actualPos > newPos ? actualPos - newPos : newPos - actualPos)
    carouselContainerRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    })

    carouselContainerRef.current.scrollBy({
      left: actualPos > newPos ? -diference : diference,
      behavior: 'smooth',
    })
    if (isSafari) {
      setCurrentItem(id)
    }
  }

  if (carouselItems.length < 1) return null

  const onClickPrev = () => {
    if (currentIndex === 0) {
      if (infinityLoop) {
        showItem(itemIDs[itemIDs?.length - 1])
      }
      // defensive, should be disabled at this point
      return
    }
    showItem(itemIDs[currentIndex - 1])
  }

  const onClickNext = () => {
    if (currentIndex === itemIDs.length - 1) {
      if (infinityLoop) {
        showItem(itemIDs[0])
      }
      // defensive, should be disabled at this point
      return
    }
    showItem(itemIDs[currentIndex + 1])
  }

  const disabledNextBtn = () => currentIndex === itemIDs.length - 1 && !infinityLoop
  const disabledPrevBtn = () => currentIndex === 0 && !infinityLoop

  return (
    <CarouselContext.Provider
      value={{
        currentItem,
        showItem,
        carouselItems,
        setOnPause,
      }}
    >
      <div
        ref={useIntersectionObserverRef}
        className={`${styles['carousel-wrapper']} ${styles[variant]} ${className ? className : ''}`}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        onTouchStart={() => setOnHover(true)}
      >
        <CarouselControl
          left
          onClick={() => {
            const timeClick = new Date().getTime()
            if ((infinityLoop && timeClick - lastClick > 1000) || !infinityLoop) {
              setLastClick(timeClick)
              onClickPrev()
              setOnPause(true)
            }
          }}
          data-testid={`${idPrefix}-control-prev`}
          disabled={disabledPrevBtn()}
          aria-disabled={disabledPrevBtn()}
          classNameControls={classNameControls}
          centerControlsUltra={centerControlsUltra}
        />
        <div
          className={`${styles['carousel-container']} ${styles[variant]}`}
          data-testid={`${idPrefix}-container`}
          ref={carouselContainerRef}
          aria-roledescription="carousel"
          role="group"
        >
          {carouselItemsRender.map((item, pos) => (
            <div
              id={item.sys.id}
              className={`${styles['carousel-container-item']} ${styles[variant]}`}
              key={item.sys.id}
              data-testid={`${idPrefix}-slide-${
                currentItem === item.sys.id ? 'selected' : 'unselected'
              }`}
              aria-roledescription="slide"
              ref={(el) => setItemsRef(el, item.sys.id, pos)}
            >
              <ItemComponent item={item} data-testid={`${idPrefix}-item`} priority={inViewport} />
            </div>
          ))}
        </div>
        <CarouselControl
          right
          onClick={() => {
            const timeClick = new Date().getTime()
            if ((infinityLoop && timeClick - lastClick > 1000) || !infinityLoop) {
              setLastClick(timeClick)
              onClickNext()
              setOnPause(true)
            }
          }}
          data-testid={`${idPrefix}-control-next`}
          disabled={disabledNextBtn()}
          aria-disabled={disabledNextBtn()}
          classNameControls={classNameControls}
          centerControlsUltra={centerControlsUltra}
        />
      </div>
      {withIndicators && (
        <CarouselIndicators
          classNameIndicators={classNameIndicators}
          onMouseEnter={() => setOnHover(true)}
          onMouseLeave={() => setOnHover(false)}
        />
      )}
    </CarouselContext.Provider>
  )
}

export const CarouselIndicators = ({ classNameIndicators }) => {
  const { carouselItems, showItem, currentItem, setOnPause } = useContext(CarouselContext)
  return (
    <div
      className={classNames(classNameIndicators, styles['dot-controls'])}
      role="group"
      aria-label="Choose a slide to display"
    >
      {carouselItems.map((item, index) => (
        <button
          key={item?.sys?.id}
          className={`
          ${styles['dot-controls-dot']} ${
            currentItem === item.sys.id ? styles['dot-controls-dot-active'] : ''
          }
          `}
          data-testid={`carousel-indicator-${index}`}
          onClick={() => {
            showItem(item.sys.id)
            setOnPause(true)
          }}
          aria-pressed={currentItem === item.sys.id}
          aria-disabled={currentItem === item.sys.id}
          aria-label={`Carousel Item ${index + 1} of ${carouselItems.length}`}
          role="button"
        />
      ))}
    </div>
  )
}
