import { useState } from 'react'
import { TitleDivider } from 'components/TitleDivider'
import { useMemoizedContentGetter } from 'utils/contentful'
import styles from './AboutUsLeadership.module.scss'
import { Grid, Collapse, Button } from '@ffn/sunbeam'
import Section from 'components/Section'
import { useViewportSmallerThan, BREAKPOINTS } from 'utils/mui'
import { CardLeadership } from 'components/Cards'
import { ChevronUp, ChevronDown } from 'react-feather'
import { object } from 'yup'
import { richTextSchema, gritItemSchema } from 'constants/validation-types'

const contentSchema = object({
  aboutUsLeadershipTitle: object({
    textContent: richTextSchema,
  }),
  aboutUsLeadershipGrid: object({
    gridItems: gritItemSchema,
  }),
})

function AboutUsLeadership({ content }) {
  const [expanded, setExpanded] = useState(false)
  const memoizedContent = useMemoizedContentGetter(content, [
    'aboutUsLeadershipTitle',
    'aboutUsLeadershipSubtitle',
    'aboutUsLeadershipGrid',
  ])
  const isMobile = useViewportSmallerThan(BREAKPOINTS.xl)

  if (!contentSchema.isValidSync(memoizedContent)) {
    console.warn('AboutUsLeadership - INVALID CONTENT RESPONSE', JSON.stringify(memoizedContent))
    return null
  }

  const { aboutUsLeadershipGrid, aboutUsLeadershipTitle } = memoizedContent

  const collapseHandler = () => {
    setExpanded((wasExpanded) => !wasExpanded)
  }

  const collapsibleItems = [...aboutUsLeadershipGrid.gridItems]
  const nonCollapsibleItems = isMobile ? collapsibleItems.splice(0, 4) : collapsibleItems

  return (
    <Section
      className={styles['about-us-leadership-section']}
      data-testid="about-us-leadership-section"
    >
      <TitleDivider
        data-testid="leadership-title-divider"
        title={aboutUsLeadershipTitle}
        isMobile={isMobile}
      />
      <div data-testid="leadership-content" className={styles['content-container']}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          rowSpacing={3}
          columnSpacing={4}
        >
          {nonCollapsibleItems.map((content, i) => {
            return (
              <Grid
                className={styles.item}
                item
                xs={12}
                md={6}
                xl={3}
                key={i}
                data-testid={`leadership-content-grid-item-${i}`}
              >
                <CardLeadership content={content} testIdIndex={i} />
              </Grid>
            )
          })}
        </Grid>
        {isMobile && (
          <>
            <Collapse in={expanded}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                rowSpacing={3}
                columnSpacing={4}
                className={styles.collapsible}
              >
                {collapsibleItems.map((content, i) => {
                  return (
                    <Grid
                      className={styles.item}
                      item
                      xs={12}
                      md={6}
                      xl={3}
                      key={i}
                      data-testid={`leadership-content-grid-item-${i + 4}`}
                    >
                      <CardLeadership content={content} testIdIndex={i + 4} />
                    </Grid>
                  )
                })}
              </Grid>
            </Collapse>
            <Button
              endIcon={expanded ? <ChevronUp /> : <ChevronDown />}
              className={styles['collapse-button']}
              onClick={collapseHandler}
              variant="text"
              data-testid="leadership-expand-button"
            >
              {expanded ? 'Show less' : 'Show more'}
            </Button>
          </>
        )}
      </div>
    </Section>
  )
}

export { AboutUsLeadership }
