import Section from 'components/Section'
import { HeroCarousel } from 'components/Carousels'
import styles from './HomeHero.module.scss'
import { get as _get } from 'lodash-es'
import { DATA_TEST_IDS } from 'constants/test-ids'
import { useMemoizedContentGetter } from 'utils/contentful'

export const HomeHero = ({ content }) => {
  const {
    homeHeroBugline,
    heroCarouselTitleA,
    heroCarouselImageA,
    heroCarouselTitleB,
    heroCarouselImageB,
    heroCarouselTitleC,
    heroCarouselImageC,
    heroCarouselCta,
  } = useMemoizedContentGetter(content, [
    'homeHeroBugline',
    'heroCarouselTitleA',
    'heroCarouselImageA',
    'heroCarouselTitleB',
    'heroCarouselImageB',
    'heroCarouselTitleC',
    'heroCarouselImageC',
    'heroCarouselCta',
  ])
  const slides = [
    {
      sys: { id: 'item1' },
      bugline: homeHeroBugline?.textContent,
      title: heroCarouselTitleA?.textContent,
      background: _get(heroCarouselImageA, 'mediaContent', ''),
      alt: _get(heroCarouselImageA, 'mediaAltText', heroCarouselTitleA?.textContent),
      backgroundMobile: _get(heroCarouselImageA, 'mobileMediaContent', ''),
      buttonCta: heroCarouselCta,
    },
    {
      sys: { id: 'item2' },
      bugline: homeHeroBugline?.textContent,
      title: heroCarouselTitleB?.textContent,
      background: _get(heroCarouselImageB, 'mediaContent', ''),
      alt: _get(heroCarouselImageB, 'mediaAltText', heroCarouselTitleA?.textContent),
      backgroundMobile: _get(heroCarouselImageB, 'mobileMediaContent', ''),
      buttonCta: heroCarouselCta,
    },
    {
      sys: { id: 'item3' },
      bugline: homeHeroBugline?.textContent,
      title: heroCarouselTitleC?.textContent,
      background: _get(heroCarouselImageC, 'mediaContent', ''),
      alt: _get(heroCarouselImageC, 'mediaAltText', heroCarouselTitleA?.textContent),
      backgroundMobile: _get(heroCarouselImageC, 'mobileMediaContent', ''),
      buttonCta: heroCarouselCta,
    },
  ]

  return (
    <Section
      childContainerClassName={styles['home-hero-container']}
      className={`${styles['home-hero-section']} main-home-hero-section`}
    >
      <HeroCarousel slides={slides} testIDPrefix={DATA_TEST_IDS.HOME.HERO} />
    </Section>
  )
}
