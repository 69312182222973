import React from 'react'
import { Typography } from 'components/Contentful'
import styles from './TitleDivider.module.scss'

function TitleDivider({
  title,
  subtitle,
  isMobile,
  className,
  titleTag = 'h2',
  subtitleTag = 'h3',
}) {
  return (
    <div className={`${styles['title-divider']} ${className ? className : ''}`}>
      {title && (
        <Typography
          data-testid="title-divider-title"
          className={styles['title']}
          content={title.textContent}
          variant={isMobile ? 'displayS30' : 'displayM30'}
          sx={{ margin: 0 }}
          fontWeight="bold"
          component={titleTag}
        />
      )}
      {subtitle && (
        <Typography
          data-testid="title-divider-subtitle"
          className={styles['subtitle']}
          content={subtitle.textContent}
          variant={isMobile ? 'bodyS30' : 'bodyM10'}
          component={subtitleTag}
        />
      )}
    </div>
  )
}

export { TitleDivider }
