import { useState, useEffect, useRef } from 'react'

/**
 *
 * @param {ReactComponentElement} element
 * @param {String} rootMargin defaults to 0
 * @returns {Boolean}
 * @example
 * import { useIntersectionObserver } from 'hooks/useIntersectionObserver'
 * import { useRef } from 'react'
 * const useIntersectionObserverRef = useRef()
 * const inViewport = useIntersectionObserver(useIntersectionObserverRef, 0)
 * <div ref={useIntersectionObserverRef}>
 *  <p>{inViewport ? "In viewport" : "Not in viewport"}</p>
 * </div>
 */
const useIntersectionObserver = (element, rootMargin = 0) => {
  const [isVisible, setIsVisible] = useState(false)
  const observerRef = useRef()

  useEffect(() => {
    // When useEffect inevitably reruns, we don't want an orphaned observer we cannot stop
    // If new IntersectionObserver is created without a ref, then the old one is orphaned
    if (typeof observerRef.current === 'function') {
      observerRef.current.disconnect()
    }

    const currentElement = element.current

    observerRef.current = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
      },
      { rootMargin: `${rootMargin}px` }
    )

    currentElement && observerRef.current.observe(currentElement)

    return () => {
      currentElement && observerRef.current.unobserve(currentElement)
    }
  }, [element, rootMargin])

  return isVisible
}

export { useIntersectionObserver }
