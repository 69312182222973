import { Typography as SunbeamTypography, Button as SunbeamButton } from '@ffn/sunbeam'
import { MadLibSelect } from './MadLibSelect'
import { Typography } from 'components/Contentful'

import styles from './MadLib.module.scss'

function MadLibForm({
  title,
  isMobile,
  lineOneOptions,
  lineOneValue,
  lineTwoOptions,
  lineTwoValue,
  button,
  onLineOneChange,
  onLineTwoChange,
  onNextClick,
}) {
  return (
    <div className={styles['mad-lib-form']} data-testid="mad-lib-container">
      <div className={styles['mad-lib-row']}>
        <Typography
          content={title}
          variant={!isMobile ? 'displayM30' : 'displayS30'}
          fontWeight="bold"
        />
      </div>
      <div className={styles['mad-lib-row']}>
        {/* NOTE: The apostrophe's don't show when rendering the lineOneText and lintTwoText from contentful */}
        <SunbeamTypography
          variant={!isMobile ? 'displayL10' : 'displayS20'}
          className={styles['mad-lib-leading-text']}
          data-testid="mad-lib-title-one"
        >
          {lineOneOptions?.leadingText}
        </SunbeamTypography>
        <MadLibSelect
          isMobile={isMobile}
          options={lineOneOptions.dropDownOptions}
          value={lineOneValue}
          onChange={(val) => onLineOneChange(val)}
          dataTestId="mad-lib-select-one"
        />
      </div>
      <div className={styles['mad-lib-row']}>
        <SunbeamTypography
          variant={!isMobile ? 'displayL10' : 'displayS20'}
          className={styles['mad-lib-leading-text']}
          data-testid="mad-lib-title-two"
        >
          {lineTwoOptions?.leadingText}
        </SunbeamTypography>
        <MadLibSelect
          isMobile={isMobile}
          options={lineTwoOptions.dropDownOptions}
          value={lineTwoValue}
          onChange={(val) => onLineTwoChange(val)}
          dataTestId="mad-lib-select-two"
        />
      </div>
      <div className={styles['mad-lib-row']}>
        <SunbeamButton
          onClick={() => onNextClick()}
          color="primary"
          variant="contained"
          size={isMobile ? 'small' : 'medium'}
        >
          {button?.fields?.linkText?.content?.[0].content?.[0].value || 'See my recommendations'}
        </SunbeamButton>
      </div>
    </div>
  )
}

export { MadLibForm }
