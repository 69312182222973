import Vimeo from '@u-wave/react-vimeo'
import { Grid, Skeleton } from '@ffn/sunbeam'
import { BREAKPOINTS } from 'utils/mui'
import styles from './VideoPlayer.module.scss'

import React, { useReducer, useEffect } from 'react'
import useViewPortSize from 'hooks/useViewportSize'

const smallDefault = {
  width: 342,
  height: 190,
}

const videoMap = {
  [BREAKPOINTS.xxl]: {
    width: 1007,
    height: 567,
  },
  [BREAKPOINTS.xl]: {
    width: 960,
    height: 540,
  },
  [BREAKPOINTS.lg]: {
    width: 704,
    height: 396,
  },
  [BREAKPOINTS.md]: {
    width: 447,
    height: 252,
  },
  [BREAKPOINTS.sm]: smallDefault,
  [BREAKPOINTS.xs]: smallDefault,
}

const reducerFunction = (state, action) => {
  switch (action.type) {
    case 'initVideo':
      return { ...state, ...action.payload }
    case 'calculatedClient':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

function VideoPlayer({ content }) {
  const { source, autoplay, altText } = content
  const { breakpointLetterSize, calculatedClient } = useViewPortSize()

  const [state, dispatch] = useReducer(reducerFunction, {
    showVideo: false,
    dimension: {
      height: videoMap[breakpointLetterSize].height,
      width: videoMap[breakpointLetterSize].width,
    },
  })

  useEffect(() => {
    if (calculatedClient) {
      dispatch({
        type: 'calculatedClient',
        payload: {
          dimension: {
            height: videoMap[breakpointLetterSize].height,
            width: videoMap[breakpointLetterSize].width,
          },
        },
      })
    }
  }, [calculatedClient, breakpointLetterSize])

  useEffect(() => {
    if (source) {
      setTimeout(() => {
        dispatch({
          type: 'initVideo',
          payload: {
            showVideo: true,
          },
        })
      }, 2000)
    }
  }, [source])

  useEffect(() => {
    const handleEvent = (event) => {
      if (['onPlay', 'onPause', 'onEnd'].indexOf(event.data) > -1) {
        //Tracking
      }
    }

    window.addEventListener('message', handleEvent)

    return () => {
      window.removeEventListener('message', handleEvent)
    }
  }, [])

  return (
    <Grid data-testid="iframe-container" style={{ height: state.dimension.height }} container>
      <div data-testid="iframe-container" className={styles['iframe-wrapper']}>
        {!state.showVideo && (
          <Skeleton
            variant="rectangular"
            width={state.dimension.width}
            height={state.dimension.height}
          />
        )}
        {state.showVideo && (
          <Vimeo
            data-testid="vimeo-iframe"
            title={altText}
            video={source}
            height={state.dimension.height}
            width={state.dimension.width}
            autopause
            autoplay={autoplay}
            onPlay={() => {
              window.parent.postMessage('onPlay', '*')
            }}
            onPause={() => {
              window.parent.postMessage('onPause', '*')
            }}
            onEnd={() => {
              window.parent.postMessage('onEnd', '*')
            }}
          />
        )}
      </div>
    </Grid>
  )
}

export { VideoPlayer }
