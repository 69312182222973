import React, { useState } from 'react'
import { MenuItem } from '@ffn/sunbeam'
import FormControl from '@mui/material/FormControl'
import { Box } from '@ffn/sunbeam'
import { Select } from '@ffn/sunbeam'
import { Grid } from '@ffn/sunbeam'
import { Typography } from 'components/Contentful/Typography'
import { useViewportSmallerThan } from 'utils/mui'
import { BLOCKS } from '@contentful/rich-text-types'
import styles from './Filter.module.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

function Filter({ jsonContent, setValue }) {
  const [filterBy, setFilterBy] = useState('*')
  const handleChange = (event) => {
    setFilterBy(event.target.value)
    setValue(event.target.value)
  }

  const isMobile = useViewportSmallerThan('lg')

  return (
    <>
      <Grid className={styles['filter-container']}>
        <Grid className={styles['filter-grid']}>
          <Typography
            className={styles['label']}
            content={jsonContent.label}
            variantOverride={{ [BLOCKS.HEADING_3]: isMobile ? 'displayXS30' : 'displayS11CTA' }}
          />

          <Grid item xs={4} lg={4}>
            <Box className={styles['box']}>
              <FormControl variant="outlined" className={styles['form-control']} fullWidth>
                <Select
                  IconComponent={KeyboardArrowDownIcon}
                  value={filterBy}
                  onChange={handleChange}
                  onClose={() => {
                    setTimeout(() => {
                      document.activeElement.blur()
                    }, 0)
                  }}
                >
                  {jsonContent.options.map((menuItem, idx) => {
                    return (
                      <MenuItem key={idx} value={menuItem.value}>
                        <Typography
                          content={menuItem.label}
                          variantOverride={{
                            [BLOCKS.HEADING_3]: isMobile ? 'displayXS30' : 'displayS11CTA',
                          }}
                        />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export { Filter }
